import { pathFor } from 'constants/apiPaths'
import React, { useEffect, useState } from 'react'
import Request from 'services/Request'
import ExportButton from 'components/Table/ExportButton'
import { PieChart, Radios } from 'components'
import { computeCount } from './utils'
import { customerIntentMappings, intentMappings } from 'constants/mappings'


function IntentReport(props) {
    const ref = React.createRef();
    const { setMessageQueue } = props
    const [ results, setResults ] = useState([])
    const [ displayData, setDisplayData ] = useState([]) 
    const [ languageSelected, setLanguageSelected ] = useState(null)
    const viewTypes = [{text: "General", value: 0}, {text: "Customer Online Mapping", value: 1}]
    const [ viewType, setViewType ] = useState(viewTypes[0].value)
    
    const parseResults = (data, _vtype) => {
        if (!!_vtype) {
            return Object.entries(customerIntentMappings).map(([intentKey, intentValues]) => {
                const categories = data.filter(item => intentValues.includes(item.intent))
                return [intentKey, categories.reduce((acc, curr) => acc + computeCount(curr.total, languageSelected), 0)]
            })
        } else {
            return Object.entries(intentMappings).map(([intentKey, intentValue]) => {
                const category = data.filter(item => item.intent === intentKey)
                return [intentValue, category?.length ? computeCount(category[0].total, languageSelected) : 0]
            })
        }
    }

    useEffect(async () => {
        try{
            const { statusCode, jsonResponse } = await Request(pathFor.intentReport)
            if (statusCode === 200) {
                const { data } = jsonResponse
                if (data.length) setResults(data[0].intents)
            } else if (statusCode === 401) {
                setMessageQueue([jsonResponse])
            } else {
                setMessageQueue([{"error": "Unable to fetch results."}])
            }
        } catch(err) {
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }, [])


    useEffect(async () => {
        if (results?.length) {
            setDisplayData(parseResults(results, viewType))
        }
    }, [ results, viewType ])

          
    return (
        <div>
            <div className="d-flex justify-content-between bg-light p-3 reportsHead mt-4">
                <Radios items={ viewTypes } selected={ viewType } setSelected={ setViewType } className="d-flex ml-1 align-items-center" />
                <ExportButton pdf={ ref } exportData={ [["Intent", "No. of URLs"], ...displayData ] } />
            </div>
            <div className='card reports'>
                <div className="d-flex justify-content-between label mx-2 p-2 pb-3">
                    <div>
                        Intent categories
                    </div>
                    <div>
                        Estimated total URLs
                    </div>
                </div>
                <div>
                    {
                        displayData.map(([intent, total]) => (
                            <div className="d-flex justify-content-between px-2 py-2 mx-2 mb-2 border-bottom">
                                <span>{ intent }</span>
                                { total }
                            </div>
                        ))
                    }
                </div>
            </div>
            <div className="mt-4 col-6 px-1 mx-1" ref={ ref }>
                <PieChart 
                    chartData={ displayData.filter(item => item[1]).map(item => ({label: item[0], value: item[1]})) }
                    title="INTENT REPORT"
                    legendDirection="right"
                    dimensions={{ height: "350", width: "400" }}
                    labelOffset={ 80 }
                    layout={{
                        padding: {
                            right: 50,
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default IntentReport