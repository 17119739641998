import React, { useEffect, useState } from 'react'
import PageLength from './PageLength'
import Pagination from './Pagination'
import Table from './Table'
import InfoDisplay from './InfoDisplay'
import ExportButton from './ExportButton'
import './styles.css'
import sortData from './utils/sortData'
import generateExportData from './utils/generateExportData'
import filterData from './utils/filterData'


function DataTable(props) {
    const { tableData, setMessageQueue, infoHead, pageLengthOptions, selectedItems, setSelectedItems, filterBy } = props
    const [ sortProps, setSortProps ] = useState(null)
    const [ currentPage, setCurrentPage ] = useState(1)
    const [ pageLength, setPageLength ] = useState(10)

    const tableHead = tableData.tableHead
    const tempBody =  filterBy ? filterData(tableData.tableBody, filterBy) : tableData.tableBody
    const tableRows = tempBody ? sortData(tempBody, sortProps) : []
    
    const total = tableRows.length
    const start = total ? pageLength * (currentPage - 1) : 0
    const count = pageLength * currentPage
    const end = !total ? 0 : total < count ? total : count  
    const tableBody = tableRows.slice(start, end)

    const exportData = generateExportData(tableHead, tableBody)
    const paginationProps = { total, pageLength, currentPage, setCurrentPage }
    
    const tableProps = {tableData: {tableHead, tableBody}, setSortProps, setMessageQueue, setSelectedItems, selectedItems}


    return (
        <div>
            {
                infoHead ? (
                    <div className="bg-light infoHead d-flex justify-content-between align-items-center py-2" >
                        <span className="text-muted mx-3">Showing upto 500 results</span>
                        <ExportButton exportData={ exportData } />
                    </div>
                ) : <></>
            }
            <div className={ `card ${ infoHead ? "reports" : "none" } w-100` }>
                <Table 
                    className="table table-borderless table-striped"
                    { ...tableProps }
                />
            </div>
            <div className="d-flex justify-content-between align-items-center my-4">
                <div>
                    <PageLength pageLength={ pageLength } setPageLength={ setPageLength } pageLengthOptions={ pageLengthOptions } />      
                    <InfoDisplay info={ { start, end, total } } />      
                </div>
                <div>
                    <Pagination { ...paginationProps } />
                </div>
            </div>
        </div>
    )
}

export default DataTable
