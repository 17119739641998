import React from 'react'
import { ReactComponent as SquareMinus } from 'assets/icons/misc/SquareMinus.svg' 
import { ReactComponent as SquarePlus } from 'assets/icons/misc/SquarePlus.svg' 
import { computeCount } from './utils'


function TaxonomyReportTable(props) {
    const { results, categorySelected, languageSelected, clicked, setClicked } = props

    const handleClick = taxonomy => {
        clicked.includes(taxonomy) ? setClicked(clicked.filter(item => item !== taxonomy)) : setClicked([taxonomy, ...clicked])
    }


    return (
        <div className='card reports'>
            <div className="d-flex justify-content-between label mx-2 p-2 pb-3">
                <div>
                    Taxonomy categories
                </div>
                <div>
                    Estimated total URLs
                </div>
            </div>
            <div>
                {
                    results.map(category => {
                        if (categorySelected && categorySelected !== category.taxonomy) return
                        return (
                            <>
                                <div className="d-flex justify-content-between p-3 border-bottom border-white bg-light rounded clickable" onClick={() => handleClick(category.taxonomy)}>
                                    <div className="d-flex">
                                        {
                                            (() => {
                                                const Icon = clicked.includes(category.taxonomy) ? SquareMinus : SquarePlus
                                                return (
                                                    <Icon width={18} height={18} fill="#0750e2" />
                                                )
                                            })()
                                        }
                                        <span className="ml-2 font-weight-bold">
                                            { category.taxonomy }
                                        </span>
                                    </div>
                                    <div className="font-weight-bold">
                                        { computeCount(category.total, languageSelected) }
                                    </div>
                                </div>
                                {
                                    clicked.includes(category.taxonomy) && category.sub_categories.map(sub_category => (
                                        <div>
                                            <div className="d-flex justify-content-between px-3 py-2 ml-5 mb-2 border-bottom">
                                                <div>
                                                    { sub_category.taxonomy }
                                                </div>
                                                <div>
                                                    { computeCount(sub_category.total, languageSelected) }                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default TaxonomyReportTable
