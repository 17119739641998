import DropDown from 'components/DropDown'
import { pathFor } from 'constants/apiPaths'
import React, { useEffect, useState } from 'react'
import Request from 'services/Request'
import ExportButton from 'components/Table/ExportButton'
import TaxonomyReportTable from './TaxonomyReportTable'
import { mapLanguage } from 'utils/mappings'
import { generateExportData } from './utils'


function TaxonomyReport(props) {
    const { currentState, setCurrentState, setMessageQueue } = props
    const [ categorySelected, setCategorySelected ] = useState(currentState && currentState.categorySelected)
    const [ languageSelected, setLanguageSelected ] = useState(currentState && currentState.languageSelected)
    const [ clicked, setClicked ] = useState([])
    const [ results, setResults ] = useState([])
    const [ languages, setLanguages ] = useState([])

    const taxonomyReportTableProps = {
        results,
        categorySelected,
        languageSelected,
        clicked,
        setClicked,
        languages
    }

    useEffect(async () => {
        try{
            const { statusCode, jsonResponse } = await Request(pathFor.taxonomyReport)
            if (statusCode === 200) {

                const { data } = jsonResponse
                
                if (data.length) {
                    setResults(data[0].taxonomies || [])
                    setLanguages(data[0].languages || [])
                }
                
            } else if (statusCode === 401) {
                setMessageQueue([jsonResponse])
            } else {
                setMessageQueue([{"error": "Unable to fetch results."}])
            }
        } catch(err) {
            setMessageQueue([{"error": "Server error. Try again after sometime."}])
        }
    }, [])

    useEffect(() => {
        setClicked([categorySelected])
        setCurrentState({categorySelected, languageSelected})
    }, [categorySelected, languageSelected])

   
    return (
        <div>
            <div className="d-flex justify-content-between bg-light p-3 reportsHead mt-4">
                <div className="d-flex mx-2">
                    <DropDown options={ results.map(item => item.taxonomy) } selected={ categorySelected } setSelected={ setCategorySelected } defaultValue="All categories" />
                    <DropDown options={ languages.map(item => {
                        let lang = mapLanguage(item)
                        return lang[0].toUpperCase() + lang.slice(1)
                    }) } selected={ languageSelected } setSelected={ setLanguageSelected } defaultValue="All languages" className="ml-3" />
                </div>
                <ExportButton exportData={ generateExportData(results, categorySelected, languageSelected) } />
            </div>
            <TaxonomyReportTable { ...taxonomyReportTableProps }/>
        </div>
    )
}

export default TaxonomyReport